<template>
  <div class="vg_wrapper">
    <el-card>
      <div class="vd_button_group vg_mtb_16">
        <el-button type="danger" size="small" @click="doDelete()" :disabled="!btn.delete"><i class="el-icon-delete"></i>删除</el-button>
        <el-button type="info" size="small" class="vd_export" icon="el-icon-refresh-right" @click="buttonRefresh()">刷新</el-button>
        <el-date-picker
          class="vg_ml_8 vg_mr_8 topDateRangeSearch"
          size="small"
          v-model="dateRange"
          type="daterange"
          range-separator="至"
          start-placeholder="录入开始日期"
          end-placeholder="录入结束日期"
        />
        <el-button type="primary" size="small" icon="el-icon-search" @click="getScons()"> 查询</el-button>
      </div>
      <search-table
        ref="multiTable"
        :data="tableData"
        :tableRowKey="`deli_id`"
        :columns="tableProperties"
        v-loading="loadFlag"
        :totalPage="totalPage"
        @select="selectRows"
        @selection-change="handleSelectionChange"
        @select-all="selectRows"
        @row-dblclick="dbClickJp"
        @getTableData="getScons"
      >
        <template v-slot:status="scope">
          <div style="text-align: center">
            <el-tag :type="helper.getStatusName(scope.row.status).type" size="mini">
              {{ helper.getStatusName(scope.row.status).name }}
            </el-tag>
          </div>
        </template>
      </search-table>
    </el-card>
  </div>
</template>

<script>
import { getNoCatch, post } from '@api/request';
import { deliAPI } from '@api/modules/deli';
import pubPagination from '@/components/common/pubPagination';
import { useCustMixin, useStffMixin } from '@/assets/js/mixin/initMixin';
import SearchTable from '@/components/table/SearchTableV2.vue';
import { getDateNoTime } from '@assets/js/dateUtils';
import { getStff } from '@api/public';
import { cloneDeep } from 'lodash';
import UrlEncode from '@assets/js/UrlEncode';

export default {
  name: 'DeliList',
  mixins: [useStffMixin, useCustMixin],
  components: {
    SearchTable,
    pubPagination
  },
  data() {
    return {
      dateRange: [],
      tableProperties: [
        this.$store.state.selection,
        this.$store.state.index,
        { prop: 'tran_no', itemType: 'input', label: '发票号', labelWidth: '180px', sortable: false },
        { prop: 'supp_abbr', itemType: 'input', label: '供应商简称', sortable: false },
        { prop: 'deli_no', itemType: 'input', label: '进仓编号', sortable: false },
        { prop: 'deli_date', itemType: 'input', label: '进仓日期', input: false, formatter: val => getDateNoTime(val, true) },
        { prop: 'cinst_abbr', itemType: 'input', label: '送货仓库', sortable: false },
        { prop: 'inst_ccontact', itemType: 'input', label: '送货联系人', sortable: false },
        { prop: 'inst_ccphone', itemType: 'input', label: '送货电话', sortable: false, input: false },
        { prop: 'inst_caddr', itemType: 'input', label: '送货地址', labelWidth: '200px', sortable: false, input: false },
        { prop: 'cust_dport', itemType: 'input', label: '目的港', labelWidth: '120px', sortable: false },
        { prop: 'totalBox', itemType: 'input', label: '总箱数', sortable: false, input: false },
        { prop: 'totalNum', itemType: 'input', label: '总数量', sortable: false, input: false },
        { prop: 'stff_name', itemType: 'select', label: '经办人', labelWidth: '100px', sortable: false, input: true, options: [], filterable: true },
        {
          prop: 'create_time',
          label: '录入时间',
          itemType: 'date',
          input: true,
          valueFormat: 'timestamp',
          formatter: val => getDateNoTime(val, true)
        },
        this.$store.state.status
      ],
      loadFlag: true,
      totalPage: 0,
      tableData: [],
      multiSelection: [],
      btn: {}
    };
  },
  mounted() {
    let { tran_no, podr_no } = this.$route.query;
    if (tran_no) this.$set(this.$refs.multiTable.searchForm, 'tran_no', tran_no);
    if (podr_no) this.$set(this.$refs.multiTable.searchForm, 'podr_no', podr_no);
    this.initData();
  },
  watch: {
    $route(to, from) {
      if (from.name === 'tranEdit') {
        this.$refs.multiTable.searchForm.tran_no = to.query.tran_no;
        this.getScons();
      }
    }
  },
  methods: {
    async initData() {
      this.getScons();
      // this.getStffUser()
      // this.getCustDport();
      // this.getCustPayway();
      // this.getInsts();
      // this.getSupps();
      this.tableProperties.find(({ prop }) => prop === 'stff_name').options = await getStff();
    },
    // 获取进仓单信息
    async getScons() {
      await (this.loadFlag = true);
      let search = cloneDeep(this.$refs.multiTable.searchForm);
      let [startTime, endTime] = cloneDeep(this.dateRange) || [];
      search.startTime = startTime?.getTime() / 1000 || null;
      search.endTime = endTime?.getTime() / 1000 || null;
      search.create_time = search.create_time / 1000 || null;
      await getNoCatch(deliAPI.getDelis, search).then(({ data }) => {
        this.tableData = data.list;
        this.totalPage = data.total;
        this.btn = data.btn;
        this.loadFlag = false;
      });
    },
    /*    // 获取供应商信息
    getSupps() {
      get(suppAPI.getSuppsV1)
        .then(res => {
          if (res.data.code === 0) {
            this.suppOptionList = res.data.data;
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    // 获取港口
    getCustDport() {
      get(optnAPI.getOptnByPermId, { perm_id: 10018 })
        .then(res => {
          if (res.data.code === 0) {
            this.custDportList = res.data.data.form.optn_cntt_list;
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    // 获取付款方式
    getCustPayway() {
      get(optnAPI.getOptnByPermId, { perm_id: 10013 })
        .then(res => {
          if (res.data.code === 0) {
            this.custPaywayList = res.data.data.form.optn_cntt_list;
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    // 查询送货仓库
    getInsts() {
      get(instAPI.getAllInstsV1, {
        page_no: 1
      }).then(res => {
        this.instOptionList = res.data.data.list;
      });
    },*/
    // 刷新
    buttonRefresh() {
      this.dateRange = [];
      this.$refs.multiTable.resetFields();
      this.getScons();
    },
    // 多选获取进仓单信息
    handleSelectionChange(val) {
      this.multiSelection = val;
    },
    selectRows(selection) {
      this.btn.delete = this.helper.isDelete(selection);
    },
    // 删除按钮
    doDelete() {
      if (this.multiSelection.length === 0) {
        this.$message({ type: 'warning', message: '请至少选择一条数据！' });
      } else {
        this.mBox();
      }
    },
    // 删除提示
    mBox() {
      this.$confirm('确定删除？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.delRow();
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
          this.$refs.multiTable.clearSelection();
        });
    },
    // 删除
    delRow() {
      const ids = [];
      this.multiSelection.map(item => {
        console.log(item);
        ids.push(item.deli_id);
      });
      post(deliAPI.deleteDeliByIds, { deli_ids: ids })
        .then(res => {
          let mg = res.data.msg;
          if (res.data.code === 0) {
            let tp = 'success';
            this.$message({ message: mg, type: tp });
            if (ids.length === this.tableData.length) {
              if (this.currentPage > 1) {
                this.currentPage = this.currentPage - 1;
                this.$refs.pubPagination.currentPage = this.currentPage;
              }
            }
            this.initData();
          } else {
            let tp = 'error';
            this.$message({ message: mg, type: tp });
            this.initData();
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
          this.initData();
        });
    },
    // 双击row跳转到详情编辑
    dbClickJp(row) {
      const permId = this.$route.query.perm_id;
      this.jump('/deli_edit', {
        key: UrlEncode.encode(
          JSON.stringify({
            perm_id: permId,
            form_id: row.deli_id
          })
        )
      });
    }
  }
};
</script>

<style scoped lang="scss"></style>
